import SocialLinks from '../../components/SocialLinks'
import {
  HomePage,
  Intro,
  Line,
  Location,
  Name,
  Role,
  SocialContainer,
} from './style'
import ArrowButton from '../../components/ArrowButton'
import useScreenSize from '../../hooks/useScreenSize'
import { size } from '../../utils/breakpoints'

const Home = ({ refName }) => {
  const isMobile = useScreenSize(size.sm)
  return (
    <HomePage ref={refName} className={'page'} id={'home'}>
      <Name>JADDIE.</Name>
      <Line />
      <Intro>
        <Role>Software Engineer </Role>
        <Location>Based in Vancouver</Location>
      </Intro>
      <SocialContainer>
        <SocialLinks />
      </SocialContainer>
      {isMobile && <ArrowButton location={'projects'} />}
    </HomePage>
  )
}

export default Home
