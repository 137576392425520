import { Button, Text } from './style'

const HoverButton = ({ text = '', width = 200, height = 45 }) => {
  const widthPx = width + 'px'
  const heightPx = height + 'px'
  const dashLength = (width + height) * 2

  const pts = `${width}, 0 ${width},${height} 0,${height} 0,0 ${width},0`
  return (
    <Button
      className="btn"
      width={widthPx}
      height={heightPx}
      length={dashLength}
    >
      <svg
        width={widthPx}
        height={heightPx}
        viewBox={'0 0 ' + width + ' ' + height}
      >
        <polyline points={pts} />
        <polyline points={pts} />
      </svg>
      <Text>{text}</Text>
    </Button>
  )
}

export default HoverButton
