import { useRef, useState } from 'react'
import SocialLinks from '../SocialLinks'
import { FooterComponent, Container, CrName, CrSym } from './style'
import useScreenSize from '../../hooks/useScreenSize'
import { size } from '../../utils/breakpoints'

const Footer = ({ refName, visible, handleHeight }) => {
  const ref = useRef(null)
  const year = new Date().getFullYear()

  const isMobile = useScreenSize(size.sm)
  const isTablet = useScreenSize(size.md)

  window.onscroll = function () {
    if (visible && ref) {
      const newHeight = Math.floor(
        window.innerHeight - ref.current.getBoundingClientRect().top
      )
      handleHeight(newHeight)
    }
  }

  return (
    <FooterComponent ref={refName}>
      <Container ref={ref}>
        <CrName>
          <CrSym>©</CrSym>Jaddie Tan {year}
        </CrName>
        <SocialLinks
          colour={'light'}
          text={isMobile ? false : true}
          scale={isTablet ? 1 : 1.1}
        />
      </Container>
    </FooterComponent>
  )
}

export default Footer
