import { HashLink } from 'react-router-hash-link'
import Chevron from '../Icons/Chevron'
import { ArrowContainer, Text } from './style'

const ArrowButton = ({ location = '' }) => (
  <HashLink smooth to={`#${location}`}>
    <ArrowContainer>
      <Text>{location}</Text>
      <Chevron />
    </ArrowContainer>
  </HashLink>
)

export default ArrowButton
