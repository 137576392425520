const Chevron = () => (
  <svg
    width="28"
    height="7"
    viewBox="0 0 28 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.19696"
      y1="0.540428"
      x2="14.197"
      y2="6.54043"
      stroke="#969696"
    />
    <line x1="13.803" y1="6.54043" x2="27.803" y2="0.540428" stroke="#969696" />
  </svg>
)

export default Chevron
