import { Title, Line } from './style'

const SectionTitle = ({ text = '', lineLength = 280 }) => {
  const lineWidth = `${lineLength}px`
  return (
    <>
      <Title>{text}</Title>
      <Line width={lineWidth} />
    </>
  )
}

export default SectionTitle
