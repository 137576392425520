import './App.scss'
import { Switch, Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import About from './pages/About'
import Experience from './pages/Experience'
import Projects from './pages/Projects'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import { useState, useEffect, useContext } from 'react'
import { useInView } from 'react-intersection-observer'

function App() {
  const [activePage, setActivePage] = useState(
    window.location.hash ? window.location.hash : '#'
  )
  const [height, setHeight] = useState(0)

  const options = { threshold: 0, rootMargin: '-50px 0px 25px' }
  const { ref: homeRef, inView: inViewHome } = useInView(options)
  const { ref: projectsRef, inView: inViewProjects } = useInView(options)
  const { ref: expRef, inView: inViewExp } = useInView(options)
  const { ref: aboutRef, inView: inViewAbout } = useInView(options)
  const { ref: footerRef, inView: inViewFooter } = useInView(options)

  useEffect(() => {
    if (inViewHome) {
      setActivePage('#home')
    } else if (inViewProjects) {
      setActivePage('#projects')
    } else if (inViewExp) {
      setActivePage('#experience')
    } else if (inViewAbout) {
      setActivePage('#about')
    }
    if (!inViewFooter) {
      setHeight(0)
    }
  }, [inViewHome, inViewProjects, inViewExp, inViewAbout, inViewFooter])

  const handleHeight = (newHeight) => {
    if (newHeight && newHeight >= 0) {
      setHeight(newHeight)
    }
    if (!inViewFooter) {
      setHeight(0)
    }
  }

  return (
    <div>
      <Navbar />
      <Navigation page={activePage} footerHeight={height} />
      <Home refName={homeRef} />
      <Projects refName={projectsRef} />
      <Experience refName={expRef} />
      <About refName={aboutRef} />
      <Footer
        refName={footerRef}
        visible={inViewFooter}
        handleHeight={handleHeight}
      />
    </div>
  )
}

export default App
