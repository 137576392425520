import styled from 'styled-components'

export const ArrowContainer = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  inset: auto 1em;
  margin: auto;
  bottom: 2em;
`

export const Text = styled.p`
  margin-bottom: 0.8em;
  letter-spacing: 0.1em;
`
