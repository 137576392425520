import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import { transition } from '../../utils/transition'

export const ExperiencePage = styled.div`
  min-height: fit-content;
`

export const Container = styled.div`
  display: flex;
  gap: 3em;
  justify-content: center;
  padding-top: 1em;
  ${transition}
  @media ${device.xl} {
    gap: 2em;
  }
  @media ${device.lg} {
    gap: 2.5em;
    flex-direction: column;
    align-items: center;
  }
  @media ${device.md} {
    gap: 1.5em;
    max-width: 80vw;
  }
`

export const Card = styled.div`
  min-width: 26em;
  padding: 3em 1.5em;
  border: 1px solid var(--dark-grey);
  position: relative;
  width: calc(65vw / 3);
  height: 9em;

  &:hover {
    box-shadow: 0 0 1px var(--grey);
  }

  @media ${device.lg} {
    width: 50vw;
    padding: 3em 2em;
  }
  @media ${device.md} {
    width: 33em;
    max-width: 70vw;
    min-width: 18em;
    padding: 2em 1.4em;
  }
`

export const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  @media ${device.lg} {
    font-weight: 400;
  }
  @media ${device.md} {
    font-size: 1.8rem;
    margin-bottom: 0.4em;
  }
`

export const Company = styled.p`
  padding-bottom: 1.5em;
  margin-bottom: 0;
  margin-top: 0.7em;
  @media ${device.md} {
    padding-bottom: 1.5em;
    font-weight: 300;
    margin-top: 0;
  }
`

export const Duration = styled.p`
  margin-bottom: 0;
  position: absolute;
  right: 2em;
  bottom: 2em;
  @media ${device.lg} {
    right: 1.4em;
  }
  @media ${device.md} {
    right: 1em;
    bottom: 1em;
  }
`
