import { Link } from 'react-router-dom'
import { Card, Company, Container, Duration, Title } from './style'

const Job = ({
  title = 'Agile Developer',
  company = 'SAP',
  duration = 'May 2022 - Dec 2022',
  link = '',
}) => {
  return (
    <Link to={link} target={'_blank'}>
      <Card>
        <Title>{title}</Title>
        <Company>{company}</Company>
        <Duration>{duration}</Duration>
      </Card>
    </Link>
  )
}

export default Job
