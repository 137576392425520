import styled, { css, keyframes } from 'styled-components'
import { device } from '../../utils/breakpoints'
import { transition } from '../../utils/transition'

export const NavBarContainer = styled.div`
  position: fixed;
  top: 0%;
  height: 7em;
  width: 100%;
  z-index: 100;

  background: linear-gradient(#eeeeec 70%, transparent);
  @media ${device.sm} {
    height: 9em;
  }
`

export const Image = styled.img`
  width: 38px;
  height: auto;
  position: absolute;
  inset: 3.5em auto auto 2em;
  ${transition}
  @media ${device.sm} {
    width: 34px;
    inset: 3.6em auto auto 4em;
  }
`

export const LinkContainer = styled.div`
  position: absolute;
  inset: 2em 0 auto auto;
  display: flex;
  align-items: center;
  padding: 0 8em 0 1em;

  background-color: var(--white-60);
  min-height: 50px;

  transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};

  @media ${device.md} {
    width: 100vw;
    height: 100vh;
    background: #f5f5f4;

    flex-direction: column;
    padding: 0;
    padding-top: 20vh;
    top: 0;
    left: 0;
    gap: 1em;
    transform: ${(props) =>
      props.open ? 'translateY(0)' : 'translateY(-100%)'};
  }
`

export const ButtonContainer = styled.span``

export const Button = styled.button`
  width: 100%;
  height: 50px;
  border: none;
  padding: 0px 0.7em;

  background: transparent;

  text-decoration: none;
  font-size: 2.2rem;
  font-weight: 300;
  color: var(--dark-grey);

  &:hover {
    text-shadow: 0 0 0.8px #333, 0 0 0.8px #333;
  }

  @media ${device.sm} {
    letter-spacing: 0.1em;
  }
`
export const ControlButton = styled(Button)`
  margin-left: 1em;

  width: 25px;
  height: 25px;

  transition: all 0.5s cubic-bezier(0.08, 0.82, 0.17, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0;

  inset: 1.5em 1.5em auto auto;
  position: absolute;

  ${(props) =>
    props?.menuopen &&
    css`
      div:nth-child(1) {
        transform: rotate(45deg);
      }
      div:nth-child(3) {
        transform: rotate(-45deg);
      }
      div:nth-child(2) {
        opacity: 0;
      }
    `}

  @media ${device.sm} {
  }
`

export const ButtonLine = styled.div`
  width: 100%;
  height: 2px;
  background: var(--dark-grey);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  position: relative;
  transform-origin: 1px;
`
