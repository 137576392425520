import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import { transition } from '../../utils/transition'

export const Title = styled.h2`
  margin-bottom: 0.3em;
  ${transition}
  @media ${device.sm} {
    font-size: 2.2rem;
    font-weight: 500;
  }
`
export const Line = styled.div`
  width: ${(props) => (props?.width ? props.width : '280px')};
  border-bottom: 1px solid var(--dark-grey);
  /* margin-bottom: 5em; */
  margin-bottom: 3vh;
  ${transition}

  @media ${device.sm} {
    width: 200px;
    margin-bottom: 2em;
  }
`
