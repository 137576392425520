export const size = {
  sm: 500,
  md: 750,
  lg: 1000,
  xl: 1250,
}

export const device = {
  sm: `( max-width: ${size.sm}px )`,
  md: `( max-width: ${size.md}px )`,
  lg: `( max-width: ${size.lg}px )`,
  xl: `( max-width: ${size.xl}px )`,
}
