import { Button, Container, Line, Page } from './style'
import { HashLink } from 'react-router-hash-link'

import data from '../../data/index.json'

const Navigation = ({ page, footerHeight = 0 }) => {
  return (
    <Container height={footerHeight}>
      {data?.navigation.map((item, idx) => {
        const hash = '#' + item
        return (
          <HashLink smooth key={idx} to={hash}>
            <Button>{page === hash ? <Page>{item}</Page> : <Line />}</Button>
          </HashLink>
        )
      })}
    </Container>
  )
}

export default Navigation
