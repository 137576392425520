import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const AboutPage = styled.div`
  padding-bottom: 5em;
  min-height: calc(88vh - 12em);
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;
  @media ${device.lg} {
    flex-direction: column;
    max-width: 80vw;
    gap: 1em;
  }
`

export const Image = styled.img`
  width: 350px;
  height: auto;
  @media ${device.lg} {
    width: 70vw;
    max-width: 300px;
  }
`
export const Text = styled.p`
  width: 500px;
  white-space: pre-wrap;
  @media ${device.lg} {
    width: 100%;
    margin-top: 0;
    max-width: 500px;
  }
`
