import SectionTitle from '../../components/SectionTitle'
import { Container, Image, Text, AboutPage } from './style'
import data from '../../data/index.json'

const About = ({ refName }) => {
  const about = data?.about
  return (
    <AboutPage className={'page'} id={'about'} ref={refName}>
      <SectionTitle text={'About'} />
      <Container>
        <Image
          src={require(`../../assets/images/${about.image}`)}
          alt="headshot"
        />
        <Text>{about.description}</Text>
      </Container>
    </AboutPage>
  )
}

export default About
