import CGitHub from '../Icons/Github'
import CLinkedIn from '../Icons/Linkedin'
import CEmail from '../Icons/Email'
import data from '../../data/index.json'
import { Container, IconButton } from './style'
import { Link } from 'react-router-dom'

const SocialLinks = ({ colour = 'dark', text = false, scale = 1.1 }) => {
  const ComponentMap = {
    GitHub: CGitHub,
    LinkedIn: CLinkedIn,
    Email: CEmail,
  }
  return (
    <Container colour={colour} scale={scale}>
      {data?.socials?.map((item, idx) => {
        let Icon = ComponentMap[item.name]
        return (
          <Link key={idx} target={'_blank'} to={item.url}>
            <IconButton>
              <Icon />
              {text && <p>{item.name}</p>}
            </IconButton>
          </Link>
        )
      })}
    </Container>
  )
}

export default SocialLinks
