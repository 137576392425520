import SectionTitle from '../../components/SectionTitle'
import Job from './Job'
import { Card, Container, ExperiencePage } from './style'
import data from '../../data/index.json'

const Experience = ({ refName }) => {
  return (
    <ExperiencePage className={'page'} id={'experience'} ref={refName}>
      <SectionTitle text={'Experience'} />
      <Container>
        {data?.experience.map((item, idx) => (
          <Job
            key={idx}
            title={item?.title}
            company={item?.company}
            duration={item?.time}
            link={item?.url}
          />
        ))}
      </Container>
    </ExperiencePage>
  )
}

export default Experience
