import { Link } from 'react-router-dom'
import {
  BottomContainer,
  Image,
  ImageContainer,
  InfoContainer,
  LanguageText,
  ProjectText,
  ProjectTitle,
  ProjectContainer,
  StyledLink,
  MobileButton,
} from './style'
import HoverButton from '../../components/HoverButton'
import useScreenSize from '../../hooks/useScreenSize'
import { size } from '../../utils/breakpoints'

const Project = ({
  name = 'To.Get Group Buy',
  description = '',
  img = '',
  url = '',
  languages = '',
}) => {
  const isTablet = useScreenSize(size.lg)
  return isTablet ? (
    <ProjectContainer>
      <ProjectTitle>{name}</ProjectTitle>
      <Link target={'_blank'} to={url}>
        <ImageContainer>
          <Image src={require(`../../assets/images/${img}`)} alt={name} />
        </ImageContainer>
      </Link>

      <StyledLink target={'_blank'} to={url}>
        <MobileButton>{'View On GitHub'}</MobileButton>
      </StyledLink>
      <InfoContainer>
        <ProjectText>{description}</ProjectText>
        <LanguageText>{languages.join(', ')}</LanguageText>
      </InfoContainer>
    </ProjectContainer>
  ) : (
    <ProjectContainer>
      <Link target={'_blank'} to={url}>
        <ImageContainer>
          <Image
            src={require(`../../assets/images/${img}`)}
            alt="to.get group buy"
          />
        </ImageContainer>
      </Link>
      <InfoContainer>
        <ProjectTitle>{name}</ProjectTitle>
        <ProjectText>{description}</ProjectText>
        <LanguageText>{languages.join(', ')}</LanguageText>
        <BottomContainer>
          <StyledLink target={'_blank'} to={url}>
            <HoverButton text={'View On GitHub'} />
          </StyledLink>
        </BottomContainer>
      </InfoContainer>
    </ProjectContainer>
  )
}

export default Project
