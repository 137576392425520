import { Container, ProjectPage } from './style'
import Project from './Project'
import data from '../../data/index.json'
import SectionTitle from '../../components/SectionTitle'

const Projects = ({ refName }) => {
  return (
    <ProjectPage className={'page'} id={'projects'} ref={refName}>
      <SectionTitle text={'Projects'} />
      <Container>
        {data?.projects.map((item, idx) => (
          <Project
            key={idx}
            name={item.name}
            description={item.description}
            img={item.image}
            url={item.url}
            languages={item.languages}
          />
        ))}
      </Container>
    </ProjectPage>
  )
}

export default Projects
