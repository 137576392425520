import { Link } from 'react-router-dom'
import './index.scss'
import Logo from '../../assets/images/logo.png'
import {
  Button,
  Image,
  LinkContainer,
  NavBarContainer,
  ControlButton,
  ButtonLine,
} from './style'
import { HashLink } from 'react-router-hash-link'
import React, { useState } from 'react'
import data from '../../data/index.json'

const Navbar = () => {
  const [open, setOpen] = useState(false)

  return (
    <NavBarContainer>
      <Link to="/">
        <Image src={Logo} alt="logo" />
      </Link>
      <LinkContainer open={open}>
        {data?.navigation.map((item, idx) => (
          <HashLink smooth to={`#${item}`} key={idx}>
            <Button onClick={() => setOpen(false)}>{item}</Button>
          </HashLink>
        ))}
      </LinkContainer>
      <ControlButton onClick={() => setOpen(!open)} menuopen={open}>
        <ButtonLine />
        <ButtonLine />
        <ButtonLine />
      </ControlButton>
    </NavBarContainer>
  )
}

export default Navbar
