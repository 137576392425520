import styled from 'styled-components'

export const Button = styled.button`
  position: relative;
  width: ${(props) => (props?.width ? props.width : '180px')};
  height: ${(props) => (props?.height ? props.height : '50px')};
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--dark-grey);
  outline: none;

  transition: 1s ease-in-out;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: var(--grey-20);
    stroke-dasharray: 150 ${(props) => (props?.length ? props.length : 490)};
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }

  &:hover {
    transition: 1s ease-in-out;
    background: var(--white-30);

    svg {
      stroke-dashoffset: -${(props) => (props?.length ? props.length : 490)};
    }
  }
`

export const Text = styled.p`
  margin: auto;
  font-weight: 300;
  letter-spacing: 1px;
`
