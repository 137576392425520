import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import { Link } from 'react-router-dom'

export const ProjectPage = styled.div`
  padding-top: 7em;
`

export const Container = styled.div`
  gap: 8em;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 80vw;
  margin: auto;
  @media ${device.lg} {
    max-width: 80vw;
    align-items: center;
    gap: 5em;
  }
  @media ${device.md} {
    gap: 3em;
  }
`

export const ProjectContainer = styled.div`
  display: flex;
  gap: 4em;
  @media ${device.lg} {
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    gap: 0;
  }
  @media ${device.sm} {
  }
`

export const ImageContainer = styled.div`
  background-color: var(--white-30);
  width: 30vw;
  max-width: 370px;
  max-height: 230px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--dark-grey);
  aspect-ratio: 1.7 / 1;
  @media ${device.xl} {
    aspect-ratio: 2/1.4;
  }
  @media ${device.lg} {
    width: 100%;
    aspect-ratio: 1.7 / 1;
    max-width: 500px;
  }
  @media ${device.md} {
  }
  @media ${device.sm} {
    max-width: 80vw;
  }
`

export const StyledLink = styled(Link)`
  @media ${device.lg} {
    margin-top: 1em;
  }
`

export const Image = styled.img`
  padding: 4em;
  overflow: hidden;
`

export const InfoContainer = styled.div`
  position: relative;
  @media ${device.lg} {
  }
`

export const ProjectTitle = styled.h3`
  font-weight: 500;
  margin: 0 auto 0.2em 0;
  @media ${device.lg} {
    margin-bottom: 0.3em;
  }
`

export const ProjectText = styled.p`
  margin-top: 0;
  @media ${device.lg} {
    margin-top: 1em;
  }
`
export const LanguageText = styled(ProjectText)`
  position: absolute;
  bottom: 40px;
  @media ${device.lg} {
    position: relative;
    text-align: right;
    bottom: 0;
  }
`

export const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
`

export const MobileButton = styled.button`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.6rem;
  width: 100%;
  border-top: 1px solid var(--dark-grey);
  border-bottom: 1px solid var(--dark-grey);
  @media ${device.sm} {
    font-size: 1.4em;
  }
`
