import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const Container = styled.div`
  display: flex;
  gap: 1.5em;
  align-items: center;
  & button {
    svg {
      scale: ${(props) => (props.scale ? props.scale : 1.1)};
      margin: auto;
      path {
        fill: ${(props) =>
          props?.colour === 'dark' ? 'var(--grey)' : 'var(--grey-70)'};
      }
      @media ${device.sm} {
        scale: 1;
      }
    }
  }
  @media ${device.sm} {
    gap: 0.1em;
  }
`

export const IconButton = styled.button`
  display: flex;
  gap: 0.5em;
  border: none;
  background: transparent;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    svg path {
      fill: ${(props) =>
        props?.colour === 'dark' ? 'var(--grey-20)' : 'var(--light-grey-20)'};
    }
    p {
      color: ${(props) =>
        props?.colour === 'dark' ? 'var(--grey-20)' : 'var(--light-grey-20)'};
    }
  }

  & > p {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-family: raleway;
    color: var(--grey-70);
  }
  @media ${device.sm} {
    padding-bottom: 0.5em;
  }
`
