const GitHub = () => (
  <svg
    width="28"
    height="27"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0.666504C12.249 0.666504 10.5152 1.01138 8.89751 1.68144C7.27984 2.35151 5.80998 3.33363 4.57187 4.57175C2.07138 7.07223 0.666626 10.4636 0.666626 13.9998C0.666626 19.8932 4.49329 24.8932 9.78663 26.6665C10.4533 26.7732 10.6666 26.3598 10.6666 25.9998V23.7465C6.97329 24.5465 6.18663 21.9598 6.18663 21.9598C5.57329 20.4132 4.70663 19.9998 4.70663 19.9998C3.49329 19.1732 4.79996 19.1998 4.79996 19.1998C6.13329 19.2932 6.83996 20.5732 6.83996 20.5732C7.99996 22.5998 9.95996 21.9998 10.72 21.6798C10.84 20.8132 11.1866 20.2265 11.56 19.8932C8.59996 19.5598 5.49329 18.4132 5.49329 13.3332C5.49329 11.8532 5.99996 10.6665 6.86663 9.71983C6.73329 9.3865 6.26663 7.99984 6.99996 6.19984C6.99996 6.19984 8.11996 5.83984 10.6666 7.55983C11.72 7.2665 12.8666 7.11984 14 7.11984C15.1333 7.11984 16.28 7.2665 17.3333 7.55983C19.88 5.83984 21 6.19984 21 6.19984C21.7333 7.99984 21.2666 9.3865 21.1333 9.71983C22 10.6665 22.5066 11.8532 22.5066 13.3332C22.5066 18.4265 19.3866 19.5465 16.4133 19.8798C16.8933 20.2932 17.3333 21.1065 17.3333 22.3465V25.9998C17.3333 26.3598 17.5466 26.7865 18.2266 26.6665C23.52 24.8798 27.3333 19.8932 27.3333 13.9998C27.3333 12.2489 26.9884 10.5151 26.3184 8.89739C25.6483 7.27971 24.6662 5.80986 23.428 4.57175C22.1899 3.33363 20.7201 2.35151 19.1024 1.68144C17.4847 1.01138 15.7509 0.666504 14 0.666504Z"
      fill="#8A8989"
    />
  </svg>
)

export default GitHub
