import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: sticky;
  top: calc(
    100vh - ${(props) => (props?.height ? `${props.height + 100}px` : '100px')}
  );
  transition: 1 ease-in-out;

  z-index: 10;

  width: min-content;
  padding: 1em;
  padding-right: 2em;
  background: var(--bg-70);
  left: 100vw;

  @media ${device.md} {
    display: none;
  }
`
export const Line = styled.div`
  width: 24px;
  height: 3px;
  background-color: var(--med-grey);
  transition: 1s ease-in-out;

  margin: 0.4em 0;
`
export const Page = styled.p`
  margin: 0;
  font-size: 2rem;
  color: var(--med-grey);
`
export const Button = styled.button`
  width: 100%;
  border: none;
  padding: 0;
  display: flex;
  justify-content: end;
  background: transparent;
  transform-origin: center right;
  transition: 0.5s cubic-bezier(0.08, 0.93, 0.32, 1.28);
  &:hover {
    scale: 1.1;
  }
`
