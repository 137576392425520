const Email = () => (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33329 21.6668C2.59996 21.6668 1.97196 21.4055 1.44929 20.8828C0.926628 20.3602 0.665739 19.7326 0.666628 19.0002V3.00016C0.666628 2.26683 0.927962 1.63883 1.45063 1.11616C1.97329 0.593498 2.60085 0.332609 3.33329 0.333498H24.6666C25.4 0.333498 26.028 0.594831 26.5506 1.1175C27.0733 1.64016 27.3342 2.26772 27.3333 3.00016V19.0002C27.3333 19.7335 27.072 20.3615 26.5493 20.8842C26.0266 21.4068 25.3991 21.6677 24.6666 21.6668H3.33329ZM14 12.1002C14.1111 12.1002 14.228 12.0833 14.3506 12.0495C14.4733 12.0157 14.5897 11.9659 14.7 11.9002L24.1333 6.00016C24.3111 5.88905 24.4444 5.74994 24.5333 5.58283C24.6222 5.41572 24.6666 5.23261 24.6666 5.0335C24.6666 4.58905 24.4777 4.25572 24.1 4.0335C23.7222 3.81127 23.3333 3.82239 22.9333 4.06683L14 9.66683L5.06663 4.06683C4.66663 3.82239 4.27774 3.81705 3.89996 4.05083C3.52218 4.28461 3.33329 4.61216 3.33329 5.0335C3.33329 5.25572 3.37774 5.45038 3.46663 5.6175C3.55552 5.78461 3.68885 5.91216 3.86663 6.00016L13.3 11.9002C13.4111 11.9668 13.528 12.017 13.6506 12.0508C13.7733 12.0846 13.8897 12.101 14 12.1002Z"
      fill="#8A8989"
    />
  </svg>
)

export default Email
