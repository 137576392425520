import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const FooterComponent = styled.div``

export const CrName = styled.p`
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: var(--grey-70);
  display: flex;
  align-items: center;
  gap: 0.4em;

  @media ${device.lg} {
    margin-top: 0;
  }
  @media ${device.sm} {
    margin-top: 0.2em;
    margin-bottom: 0;
  }
`
export const CrSym = styled.span`
  font-size: 2.4rem;
`
export const Container = styled.div`
  height: 130px;
  align-items: center;
  display: flex;
  padding: 0 8em;
  justify-content: space-between;
  border-top: 2px solid var(--grey-50);
  max-width: 100vw;

  @media ${device.lg} {
    height: 130px;
    padding: 1em 1.5em;
    flex-direction: column-reverse;
    justify-content: center;
  }
`
