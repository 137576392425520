import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import { transition } from '../../utils/transition'

export const HomePage = styled.div`
  padding: 10%;
  min-height: 90vh;
  margin-top: -7em;
`

export const Name = styled.h1`
  font-weight: 300;
  font-size: 7.2em;
  letter-spacing: 0.2em;
  margin-top: 26vh;

  ${transition}
  @media ${device.sm} {
    margin-top: 38vh;
    font-size: 4.2em;
  }
`
export const Line = styled.hr`
  width: 100px;
  margin-left: 0;
  height: 1px;
  border-color: var(--dark-grey);
  background: var(--dark-grey);
  @media ${device.sm} {
    height: 0.5px;
  }
`

export const Intro = styled.span`
  display: flex;
  align-items: center;
  gap: 0.4em;
  font-size: 2.4em;
  color: #8a8989;
  font-weight: 300;
  letter-spacing: 0.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  transition: ease all 0.5s;
  @media ${device.md} {
    flex-direction: column;
    align-items: start;
  }
  @media ${device.sm} {
    font-size: 2em;
  }
`

export const Role = styled.span`
  font-weight: 500;
`

export const Location = styled.span`
  @media ${device.md} {
    margin-top: -0.3em;
  }
  @media ${device.sm} {
    margin-top: -0.1em;
  }
`

export const SocialContainer = styled.div`
  /* margin-left: -0.5em; */
  margin-left: -1.1em;
  /* margin-top: 2em; */
`
